import styled from "styled-components";

export const MainContainer = styled.div`
width: 100%;
margin: 50px auto;
display: flex;
flex-direction: column;
align-items: center;

`

export const CountRow = styled.div`
width: 300px;
height: 35px;
display: flex;
justify-content: space-between;
align-items: center;
`

export const CountValue = styled.div`
font-weight: bold;
`


export const Btn = styled.button`
  width: 210px;
  height: 30px;
  border: 1px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  font-size: 18px;

  &:hover {
    color: purple;
    border-color: purple;
    background-color: black;
  }

  &.disabled {
    cursor: not-allowed;
  }
`
