import styled from "styled-components";

export const MainContainer = styled.div`
width: 100%;
min-height: 100vh;
display: flex;
align-items: center;
flex-direction: column;
`

export const MainContent = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`
