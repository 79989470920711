import React from 'react';
import { MainContainer, MainContent } from './Home.styles'

const Home = () => {
  return (
    <MainContainer>
      <MainContent>
        PLEASE RE-ENTER THE URL (WEB ADDRESS) CORRECTLY!
      </MainContent>
    </MainContainer>
  );
};

export default Home;
