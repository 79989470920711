import React, { useContext } from 'react';
import { MainContainer } from './Admin.styles';
import Auth from '../../components/auth/Auth';
import { UserContext } from '../../contexts/user-context';
import AdminDashboard from '../../components/dashboards/AdminDashboard';

const Admin = () => {
  const { user } = useContext(UserContext)

  return (
    <MainContainer>

      {
        user ? <>
          {/* <Spinner />
        <button onClick={handleLogout}>logout</button> */}
          <AdminDashboard />
        </> : <Auth />
      }

    </MainContainer>
  );
};

export default Admin;
