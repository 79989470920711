import React, { useState } from "react";
import { MainContainer, TabsContainer, TabItem } from "./Auth.styles";
import Login from "./Login";
import Signup from "./Signup";

const Auth = () => {
  const [tab, setTab] = useState("login");

  return (
    <MainContainer>
      <TabsContainer>
        <TabItem
          onClick={() => setTab("login")}
          className={tab === "login" && "selected"}
        >
          Login
        </TabItem>
        {/* <TabItem
          onClick={() => setTab("signup")}
          className={tab === "signup" && "selected"}
        >
          Signup
        </TabItem> */}
      </TabsContainer>

      {tab === "login" && <Login />}

      {tab === "signup" && <Signup />}
    </MainContainer>
  );
};

export default Auth;
