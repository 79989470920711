import styled from "styled-components";

export const MainContainer = styled.div`
width: 100%;
height: 30px;
display: flex;
justify-content: space-between;
align-items: center;
padding: 0 5px;

&:nth-child(odd) {
    background-color: lightgray;
}
`

export const Value = styled.div`
text-align: left;

&:nth-child(1) {
  width: 190px;
}

&:nth-child(2) {
  width: 160px;
}

&:nth-child(3) {
  width: 250px;
}
&:nth-child(4) {
  width: 170px;
}
&:nth-child(5) {
  width: 300px;
}
&:nth-child(6) {
  width: 100px;
}
&:nth-child(7) {
  width: 100px;
}
&:nth-child(8) {
  width: 100px;
}
&:nth-child(9) {
  width: 120px;
}
&:nth-last-child(1) {
  width: 170px;
  display: flex;
  justify-content: space-between;

}
`

export const QueueValueRow = styled.div`
width: 100%;
`

export const Btn = styled.button`
width: 100%;
background-color: purple;
color: white;
font-weight: bold;
cursor: pointer;
transition: all 0.3s;

&:hover {
  color: black;
  background-color: gray;
}

&:active {
  color: black;
  background-color: lightgray;
}

`

export const QueueValue = styled.div`
&:nth-child(1) {
  width: 200px;
  text-align: center;
}
&:nth-child(2) {
  width: calc(100% - 200px);
  text-align: center;
}
`
