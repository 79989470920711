import styled from "styled-components";

export const MainContainer = styled.div`
width: 100%;
`

export const MainContent = styled.div`
width: 400px;
margin: auto;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;

form {
  width: 100%;
  margin-top: 10px;
}
`

export const Label = styled.label`
  width: 100%;
  display: inline-block;
  font-weight: bold;
  padding-left: 2px;
  margin-bottom: 5px;
  color: black;
  text-align: left;
`

export const Input = styled.input`
  width: 100%;
  border-radius: 5px;
  outline: none;
  height: 40px;
  font-size: 16px;
  padding: 0 10px;
  color: purple;
  border-color: purple;
  margin-bottom: 10px;
`

export const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`

export const Btn = styled.button`
  width: 100%;
  height: 40px;
  border: 1px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  font-size: 18px;

  &.disabled {
    cursor: not-allowed;
  }

  &:hover {
    color: purple;
    border-color: purple;
    background-color: black;
  }
`

export const ErrMsg = styled.div`
margin: 20px auto;
color: red;
text-transform: uppercase;
span {
  font-weight: bold;
}
`

export const ContactContainer = styled.div`
width: 100%;
margin: 20px auto;
`

export const ContactValueRow = styled.div`
width: 100%;
height: 30px;
display: flex;
justify-content: space-between;
align-items: center;
font-weight: bold;
padding: 0 5px;
text-transform: capitalize;

&:nth-child(odd) {
    background-color: lightgray;
}
`
