import styled from "styled-components";

export const MainContainer = styled.div`
width: 100%;
`

export const MainContent = styled.div`
width: 1470px;
margin: auto;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;

&.queueUser {
  width: 100%;
}
`

export const HeadRow = styled.div`
width: 100%;
height: 30px;
display: flex;
justify-content: space-between;
align-items: center;
background-color: gray;
padding: 0 5px;
font-weight: bold;
`

export const Value = styled.div`
text-align: left;

&:nth-child(1) {
  width: 190px;
}

&:nth-child(2) {
  width: 160px;
}

&:nth-child(3) {
  width: 250px;
}
&:nth-child(4) {
  width: 170px;
}
&:nth-child(5) {
  width: 300px;
}
&:nth-child(6) {
  width: 100px;
}
&:nth-child(7) {
  width: 100px;
}
&:nth-child(8) {
  width: 100px;
}
&:nth-child(9) {
  width: 120px;
}
&:nth-last-child(1) {
  width: 170px;
  display: flex;
  justify-content: space-between;
}

`

export const QueueValue = styled.div`
&:nth-child(1) {
  width: 200px;
  text-align: center;
}
&:nth-child(2) {
  width: calc(100% - 200px);
  text-align: center;
}
`
