import styled from "styled-components";

export const MainContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin: 50px auto;
`

export const Heading1 = styled.div`
font-weight: bold;
font-size: 40px;
margin-bottom: 15px;
text-align: center;
`

export const SubHeading = styled.div`
font-size: 18px;
margin-bottom: 50px;
text-align: center;
`

export const StepsContainer = styled.div`
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  justify-content: center;

  @media only screen and (max-width: 1080px) {
    grid-template-columns: 1fr;
  }
`;


export const StepCol = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr); /* Ensures equal height for items */
  gap: 10px;
  background-color: lightgray;
  padding: 25px 20px 0;
`

export const StepDescription = styled.div`
  margin-bottom: 5px;
  line-height: 25px;
`

export const StepTitleContainer = styled.div`
  width: 90%;
  height: 50px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  &.lending {
    background-color: ${props => props.theme.colors.darkLending};
  }

  &.debt {
    background-color: ${props => props.theme.colors.darkDebt};
  }

  &.tax {
    background-color: ${props => props.theme.colors.darkTax};
  }
`

export const StepTitleNumber = styled.div`
  background-color: lightgray;
  color: black;
  width: 70px;
  height: 30px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`

export const StepTitleDescription = styled.div`
  font-weight: bold;
  color: white;
  width: calc(100% - 90px);
`
