import React, { useState, useRef, useContext } from "react";
import {
  db,
  getDoc,
  doc,
  setDoc,
  usersRef,
  auth,
  serverTimestamp,
  createUserWithEmailAndPassword,
  signOut,
} from "../../config";
import { UserContext } from "../../contexts/user-context";
import {
  Btn,
  BtnContainer,
  Form,
  Input,
  InputRow,
  Label,
  MainContent,
} from "./Auth.styles";

const Signup = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const formRef = useRef();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const fetchUserByUID = async (uid) => {
    try {
      // Create a reference to the user document
      const userDocRef = doc(db, "users", uid);

      // Fetch the document
      const docSnap = await getDoc(userDocRef);

      if (docSnap.exists()) {
        return docSnap.data(); // or handle the data as needed
      } else {
        console.error("No user found!");
        return null; // Handle the case where the user document does not exist
      }
    } catch (error) {
      console.error("Error fetching user document: ", error);
      // Handle any errors that occur during fetch
    }
  };

  const handleSignup = (e) => {
    e.preventDefault();

    setIsLoading(true);
    const formData = new FormData(formRef.current);

    const email = formData.get("email");
    const password = formData.get("password");

    createUserWithEmailAndPassword(auth, email, password)
      .then((cred) => {
        const userDocRef = doc(usersRef, cred.user.uid);

        setDoc(userDocRef, {
          id: cred.user.uid,
          email: email,
          fname: formData.get("fname"),
          lname: formData.get("lname"),
          role: "agent",
          createdAt: serverTimestamp(),
        })
          .then(() => {
            formRef.current.reset();

            const userDoc = fetchUserByUID(cred.user.uid);

            setUser(userDoc);
          })
          .catch((err) => {
            console.error("Error setting document: ", err.message);
          });
      })
      .catch((err) => {
        console.error("Error creating user: ", err.message);
      });

    setIsLoading(false);
  };

  return (
    <MainContent>
      <Form ref={formRef} onSubmit={handleSignup}>
        <InputRow>
          <Label htmlFor="email">Email:</Label>
          <Input type="text" name="email" id="email" />
        </InputRow>

        <InputRow>
          <Label htmlFor="password">Password:</Label>
          <Input type="password" name="password" id="password" />
        </InputRow>
        <InputRow>
          <Label htmlFor="fname">First Name:</Label>
          <Input type="text" name="fname" />
        </InputRow>
        <InputRow>
          <Label htmlFor="lname">Last Name:</Label>
          <Input type="text" name="lname" />
        </InputRow>
        <BtnContainer>
          <Btn disabled={isLoading} type="submit">
            Submit
          </Btn>
        </BtnContainer>
      </Form>
    </MainContent>
  );
};

export default Signup;
