import React, { useState, useEffect } from "react";
import { Btn, MainContainer, MainContent } from "./Upload.styles";
import { contactsData } from "../../../data/part_10";
// import { debtAmountData } from "../../../data/jr/jr-122923-debt-amount-20";
import { functions, httpsCallable, collection, db, query, where, getDocs, updateDoc } from "../../../config";

const Upload = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!contactsData) return;
    setIsLoading(true);

    setData(contactsData);
    setIsLoading(false);
  }, [contactsData]);

  const handleLogData = () => {
    if (!data) return;
    setIsLoading(true);
    console.log("Data: ", contactsData);
    console.log("Count: ", contactsData.length);
    setIsLoading(false);
  };

  // const handleUploadData = () => {
  //   if (!data) return;
  //   setIsLoading(true);
  //   const addContacts = httpsCallable(functions, "addContacts");
  //   addContacts({ contacts: data }) // Assuming 'data' is your state with the array of contacts
  //     .then((result) => {
  //       console.log(result.data);
  //     })
  //     .then(() => {
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error(error); // Handle errors
  //       setIsLoading(false);
  //     });
  // };

  // const updateSpacedParams = async () => {
  //   setIsLoading(true);
  //   try {
  //     for (const item of data) {
  //       const { ref, param } = item;
  //       const contactsRef = collection(db, 'contacts');
  //       const q = query(contactsRef, where('ref', '==', ref));
  //       const querySnapshot = await getDocs(q);

  //       if (!querySnapshot.empty) {
  //         querySnapshot.forEach((doc) => {
  //           const docRef = doc.ref;
  //           updateDoc(docRef, { param: param });
  //         });
  //       }
  //     }
  //     console.log('Update operation completed successfully.');
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error('Error updating contacts:', error);
  //     setIsLoading(false);
  //   }
  // };

  // const handleRemoveContactsByRef = () => {
  //   setIsLoading(true);
  //   const removeContactsByRef = httpsCallable(functions, "removeContactsByRef");
  //   removeContactsByRef([

  //   ])
  //     .then((result) => {
  //       // Read result of the Cloud Function.
  //       console.log(result.data); // Use .data to access the result's data object
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log("error: ", error);
  //       setIsLoading(false);
  //     });
  // };

  // const updateDebts = async () => {
  //   if (!debtAmountData) return;
  //   setIsLoading(true);
  //   const updateDebtsFunc = httpsCallable(functions, "updateDebts");

  //   try {
  //     const result = await updateDebtsFunc(debtAmountData);
  //     console.log(result.data.result);
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error("Error updating debts:", error);
  //     setIsLoading(false);
  //   }
  // };

  // const updateMiddleName = async () => {
  //   setIsLoading(true);
  //   const updateMiddleNameFunction = httpsCallable(
  //     functions,
  //     "updateMiddleName"
  //   );
  //   try {
  //     const result = await updateMiddleNameFunction();
  //     console.log(
  //       `Result Message: ${result.data.message}. Total Found: ${result.data.totalFound}, Updated: ${result.data.updatedCount}`
  //     );
  //     console.log(`Result: ${result}`);
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log(
  //       `Error Message: ${error.message}. Total Found: ${
  //         error.totalFound || "N/A"
  //       }, Updated: ${error.updatedCount || "N/A"}`
  //     );
  //     console.log(`Error: ${error}`);
  //     setIsLoading(false);
  //   }
  // };

  return (
    <MainContainer>
      <MainContent>
        <Btn
          disabled={isLoading}
          className={isLoading && "disabled"}
          onClick={handleLogData}
        >
          Log Local Contacts
        </Btn>
        {/* <Btn
          disabled={isLoading}
          className={isLoading && "disabled"}
          onClick={handleUploadData}
        >
          Upload Contacts
        </Btn> */}
        {/* <Btn
          disabled={isLoading}
          className={isLoading && "disabled"}
          onClick={updateSpacedParams}
        >
          Update Spaced Params
        </Btn> */}
        {/* <Btn
          disabled={isLoading}
          className={isLoading && "disabled"}
          onClick={handleRemoveContactsByRef}
        >
          Remove Contacts By Ref
        </Btn> */}
        {/* <Btn
          disabled={isLoading}
          className={isLoading && "disabled"}
          onClick={updateDebts}
        >
          Update Debt Amount
        </Btn> */}
        {/* <Btn
          disabled={isLoading}
          className={isLoading && "disabled"}
          onClick={updateMiddleName}
        >
          Update Middle Name
        </Btn> */}
      </MainContent>
    </MainContainer>
  );
};

export default Upload;
