import styled from "styled-components";

export const MainContainer = styled.div`
width: 100%;
padding: 10px 0;
`

export const MainContent = styled.div`
width: 90%;
margin: auto;
display: flex;
justify-content: center;
align-items: center;
`



// export const LeftCol = styled.div`

// `

// export const RightCol = styled.div`

// text-align: right;
// `

// export const RightColTop = styled.div`
// color: gray;
// font-weight: 500;
// margin-bottom: 10px;

// `

// export const RightColBottom = styled.div`
// font-weight: bold;
// font-size: 20px;

// a{
//   color: inherit;
//   text-decoration: none;
// }
// `



export const Logo = styled.img`
height: 100px;
`
