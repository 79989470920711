import styled from "styled-components";

export const MainContainer = styled.div`
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
`

export const MainContent = styled.div`
width: 90%;
text-align: center;
font-weight: bold;
letter-spacing: 5px;
`
