import React from "react";
import {
  CloseIcon,
  CloseIconContainer,
  MainContainer,
  MainContent,
  TextContent,
  TextContentContainer,
} from "./TermAndConditions.styles";

const TermAndConditions = ({ setDisplayTerms }) => {
  return (
    <MainContainer>
      <MainContent>
        <CloseIconContainer>
          <CloseIcon onClick={() => setDisplayTerms(false)}>x</CloseIcon>
        </CloseIconContainer>
        <TextContentContainer>
          <TextContent>
            By submitting this form, I am providing express written consent to
            be contacted by Emerald Gain via SMS/MMS text messages to the number
            I provided above, to discuss the products and services offered by
            Emerald Gain including telemarketing sales calls and information
            calls in response to your requests, to complete transactions, and to
            facilitate any service offering. I acknowledge and agree that I am
            authorized to receive calls at the number provided and to consent to
            receive those calls from Emerald Gain. I also agree to receive
            e-mails from Emerald Gain including e-mails to my mobile device. I
            waive any registration to any state, federal, or corporate Do Not
            Call registry for purposes of such calls. I understand consent is
            not required to purchase goods or services and that message and data
            rates may apply.
          </TextContent>
        </TextContentContainer>
      </MainContent>
    </MainContainer>
  );
};

export default TermAndConditions;
