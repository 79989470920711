import React, { useState } from "react";
import { Btn, MainContainer, CountRow, CountValue } from "./Counts.styles";
import { functions, httpsCallable } from "../../../config";
import Spinner from "../../spinner/Spinner";

const Counts = () => {
  const [values, setValues] = useState({
    all: "-",
    debt: "-",
    lending: "-",
    tax: "-",
  });
  const [isLoading, setIsLoading] = useState(false);

  const getCount = (department) => {
    setIsLoading(true);
    const getCountByDepartment = httpsCallable(
      functions,
      "getCountByDepartment"
    );

    getCountByDepartment({ department })
      .then((result) => {
        setValues({ ...values, [department]: result.data.count });
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setIsLoading(false);
  };

  return (
    <MainContainer>
      <CountRow>
        <Btn
          className={(isLoading || values.all !== "-") && "disabled"}
          onClick={() => getCount("all")}
          disabled={isLoading || values.all !== "-"}
        >
          {isLoading ? <Spinner /> : "All"}
        </Btn>
        <CountValue>{values.all}</CountValue>
      </CountRow>

      <CountRow>
        <Btn
          className={(isLoading || values.debt !== "-") && "disabled"}
          onClick={() => getCount("debt")}
          disabled={isLoading || values.debt !== "-"}
        >
          {isLoading ? <Spinner /> : "Debt"}
        </Btn>
        <CountValue>{values.debt}</CountValue>
      </CountRow>

      <CountRow>
        <Btn
          className={(isLoading || values.lending !== "-") && "disabled"}
          onClick={() => getCount("lending")}
          disabled={isLoading || values.lending !== "-"}
        >
          {isLoading ? <Spinner /> : "Lending"}
        </Btn>
        <CountValue>{values.lending}</CountValue>
      </CountRow>

      <CountRow>
        <Btn
          className={(isLoading || values.tax !== "-") && "disabled"}
          onClick={() => getCount("tax")}
          disabled={isLoading || values.tax !== "-"}
        >
          {isLoading ? <Spinner /> : "Tax"}
        </Btn>
        <CountValue>{values.tax}</CountValue>
      </CountRow>
    </MainContainer>
  );
};

export default Counts;
