import React from "react";
import {
  ConfirmationContainer,
  ConfirmationContent,
  Img,
  MainContainer,
  TYContent,
  TYHeading,
  TYSubHeading,
} from "./Lend.styles";
import EmeraldGainLendingSymbol from "../../assets/img/EmeraldGain-Lending-symbol.png";

const ThankYou = ({ firstName }) => {
  return (
    <MainContainer>
      <ConfirmationContainer>
        <ConfirmationContent>
          <TYContent>
            <TYHeading>Great News, {firstName}!</TYHeading>
            <TYSubHeading>
              Your application has been verified and assigned!
            </TYSubHeading>
            <Img
              className="symbol"
              src={EmeraldGainLendingSymbol}
              alt="Emerald Gain logo"
            />
            <TYSubHeading>
              Next, please <a href="tel:+18886320709">CLICK HERE</a> to speak
              with your assigned Consultant and confirm your savings options and
              terms.
            </TYSubHeading>
          </TYContent>
        </ConfirmationContent>
      </ConfirmationContainer>
    </MainContainer>
  );
};

export default ThankYou;
