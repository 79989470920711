import React, { useContext } from "react";
import {
  Heading1,
  MainContainer,
  StepCol,
  StepDescription,
  StepTitleContainer,
  StepTitleDescription,
  StepTitleNumber,
  StepsContainer,
  SubHeading,
} from "./ProcessSteps.styles";
import { ServiceContext } from "../../contexts/service-context";

const ProcessSteps = () => {
  const { service } = useContext(ServiceContext);

  return (
    <>
      {(service === "debt" || service === "lending" || service === "tax") && (
        <MainContainer>
          <Heading1>3 Simple Steps</Heading1>
          <SubHeading>
            Our simplified application lets you submit your details
            effortlessly, ensuring full transparency throughout the entire
            process.
          </SubHeading>
          <StepsContainer>
            <StepCol>
              <StepDescription>
                Utilize our straightforward online form to guide you through a
                comprehensive debt analysis. Gain insight into the portion of
                your monthly income dedicated to debt payments.
              </StepDescription>
              <StepTitleContainer className={service}>
                <StepTitleNumber>STEP 1</StepTitleNumber>
                <StepTitleDescription>
                  Debt Analysis at No Cost
                </StepTitleDescription>
              </StepTitleContainer>
            </StepCol>

            <StepCol>
              <StepDescription>
                Examine tailored alternatives aligned with your income. Take
                control of your file with a personalized portfolio for hands-on
                management.
              </StepDescription>
              <StepTitleContainer className={service}>
                <StepTitleNumber>STEP 2</StepTitleNumber>
                <StepTitleDescription>Discover Solutions</StepTitleDescription>
              </StepTitleContainer>
            </StepCol>
            <StepCol>
              <StepDescription>
                Upon approval, gain access to complimentary tools facilitating
                your journey towards financial independence. Discover innovative
                ways to invest and save, as financial freedom awaits just a
                click away!
              </StepDescription>
              <StepTitleContainer className={service}>
                <StepTitleNumber>STEP 3</StepTitleNumber>
                <StepTitleDescription>Financial Security</StepTitleDescription>
              </StepTitleContainer>
            </StepCol>
          </StepsContainer>
        </MainContainer>
      )}
    </>
  );
};

export default ProcessSteps;
