import { initializeApp } from "firebase/app";
import { collection, updateDoc, getFirestore, serverTimestamp, onSnapshot, orderBy, Timestamp, addDoc, query, where, getDoc, getDocs, doc, setDoc } from 'firebase/firestore'
import { getAuth, createUserWithEmailAndPassword, signOut, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

initializeApp(firebaseConfig);

const db = getFirestore()
const auth = getAuth()
const functions = getFunctions();

const usersRef = collection(db, 'users')
const contactsRef = collection(db, 'contacts')

export { usersRef, contactsRef, createUserWithEmailAndPassword, signOut, signInWithEmailAndPassword, auth, doc, db, collection, query, where, onSnapshot, orderBy, getDocs, getDoc, setDoc, updateDoc, serverTimestamp, Timestamp, functions, httpsCallable }
