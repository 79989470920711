import React, { useState, useRef, useContext } from "react";
import {
  Btn,
  BtnContainer,
  Form,
  Input,
  InputRow,
  Label,
  MainContent,
} from "./Auth.styles";
import {
  signInWithEmailAndPassword,
  auth,
  doc,
  db,
  getDoc,
} from "../../config";
import { UserContext } from "../../contexts/user-context";

const Login = () => {
  const { setUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef();

  const fetchUserByUID = async (uid) => {
    try {
      // Create a reference to the user document
      const userDocRef = doc(db, "users", uid);

      // Fetch the document
      const docSnap = await getDoc(userDocRef);

      if (docSnap.exists()) {
        return docSnap.data(); // or handle the data as needed
      } else {
        console.error("No user found!");
        return null; // Handle the case where the user document does not exist
      }
    } catch (error) {
      console.error("Error fetching user document: ", error);
      // Handle any errors that occur during fetch
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData(formRef.current);
    const email = formData.get("email");
    const password = formData.get("password");

    try {
      const cred = await signInWithEmailAndPassword(auth, email, password);
      formRef.current.reset();
      const userDoc = await fetchUserByUID(cred.user.uid);

      if (userDoc) {
        localStorage.setItem("user", JSON.stringify(userDoc));
        setUser(userDoc);
      } else {
        // Handle the case where the user doc is not found
        console.error("No user found!");
      }
    } catch (err) {
      console.error(err.message);
      // Handle any errors in sign in or fetching user document
    }

    setIsLoading(false);
  };

  return (
    <MainContent>
      <Form ref={formRef} onSubmit={handleLogin}>
        <InputRow>
          <Label htmlFor="email">Email:</Label>
          <Input type="text" name="email" id="email" />
        </InputRow>
        <InputRow>
          <Label htmlFor="password">Password:</Label>
          <Input type="password" name="password" id="password" />
        </InputRow>
        <BtnContainer>
          <Btn disabled={isLoading} type="submit">
            Login
          </Btn>
        </BtnContainer>
      </Form>
    </MainContent>
  );
};

export default Login;
