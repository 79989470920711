import styled from "styled-components";

export const MainContainer = styled.div`
width: 90%;
max-width: 380px;
min-width: 300px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-top: 50px;
`


export const TabsContainer = styled.div`
width: 100%;
height: 50px;
display: flex;
justify-content: space-between;
align-items: center;
`

export const TabItem = styled.div`
width: calc(50% - 10px);
height: 100%;
display: flex;
justify-content: center;
align-items: center;
color: gray;
border: 1px solid gray;
cursor: pointer;
transition: all 0.3s;
border-radius: 5px;

&.selected, &:hover {
  color: white;
  border-color: white;
}

`

export const MainContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Form = styled.form`
width: 100%;
`

export const InputRow = styled.div`
  margin: 20px 0;
`

export const Label = styled.label`
  width: 140px;
  display: inline-block;
  font-weight: bold;
  padding-left: 2px;
  margin-bottom: 5px;
  color: white;
`

export const Input = styled.input`
  width: 100%;
  border-radius: 5px;
  outline: none;
  height: 40px;
  font-size: 16px;
  padding: 0 10px;
  color: purple;
  border-color: purple;
`

export const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
`

export const Btn = styled.button`
  width: 100%;
  height: 40px;
  border: 1px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  font-size: 18px;

  &:hover {
    color: purple;
    border-color: purple;
    background-color: black;
  }
`
