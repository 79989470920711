import React from "react";
import ProcessSteps from "../../components/process-steps/ProcessSteps";
import { MainContainer, MainContent } from "./Tax.styles";
import Hero from "../../components/hero/Hero";

const Tax = () => {
  return (
    <MainContainer>
      <Hero text='SEIZE THE OPPORTUNITY FOR A BRIGHTER FUTURE WITH OUR HASSLE-FREE TAX SOLUTIONS.' />
      <MainContent>
        <ProcessSteps />
      </MainContent>
    </MainContainer>
  );
};

export default Tax;
