import styled from "styled-components";

export const MainContainer = styled.div`
width: 100%;
`

export const MainContent = styled.div`
width: 100%;
`

export const UserName = styled.div`
width: 100%;
text-align: center;
font-weight: bold;
margin-top: 20px;
`

export const SectionNav = styled.div`
width: 100%;
display: flex;
justify-content: end;
margin: 20px auto;
`

export const TabsContainer = styled.div`
margin: auto;
display: inline-flex;
justify-content: center;
border: 1px solid gray;
border-radius: 5px;
`

export const TabItem = styled.div`
width: 100px;
height: 30px;
display: flex;
justify-content: center;
align-items: center;
color: gray;
background-color: ;
cursor: pointer;
transition: all 0.3s;
border-radius: 5px;
font-weight: bold;

&.selected, &:hover {
  color: black;
  background-color: gray;
}

`

export const Btn = styled.button`
  width: 70px;
  height: 30px;
  border: 1px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  font-size: 18px;

  &:hover {
    color: purple;
    border-color: purple;
    background-color: black;
  }
`
