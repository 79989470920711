import React, { useState, useEffect } from "react";
import { TY } from "./Form.styles";

const ThankYou = ({ firstName, transfer, service }) => {
  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (!transfer) return;

    setPhone(transfer.replace(/\D/g, ""));
  }, [transfer]);

  return (
    <>
      <TY className={`top ${service}`}>{`Thank you, ${firstName}!`}</TY>
      <TY className="small">A loan specialist has received your submission.</TY>
      <TY className="middle">
        You will be receiving a follow-up call from a 949-area code number to
        confirm your loan application information.
      </TY>
      <TY className="small">To get a head start, please call:</TY>
      <TY className={`bottom ${service}`}>
        <a href={`tel:${phone}`}>{transfer}</a>
      </TY>
    </>
  );
};

export default ThankYou;
