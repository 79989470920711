import styled from "styled-components";

export const MainContainer = styled.div`
width: 100%;
`

export const MainContent = styled.div`
width: 90%;
margin: auto;
`
