import React, { useState, useRef } from "react";
import { functions, httpsCallable } from "../../config";
import { Btn, BtnContainer, FormContainer, Input, Label } from "./Form.styles";
import Spinner from "../spinner/Spinner";

const Access = ({
  isLoading,
  setIsLoading,
  uniqueUrl,
  setIsInvalid,
  setContact,
  setStep,
  service,
}) => {
  const [accessCode, setAccessCode] = useState(null);

  const formRef = useRef(null);

  const lookupContact = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    formRef.current.reset();

    if (!uniqueUrl || !accessCode || !service) {
      setAccessCode(null);
      setIsInvalid(true);
      setIsLoading(false);
      return;
    }

    const checkContact = httpsCallable(functions, "lookupContact");

    try {
      const result = await checkContact({
        accessCode: accessCode,
        param: uniqueUrl,
        service: service,
      });

      formRef.current.reset();

      if (result.data === false) {
        setAccessCode(null);
        setIsInvalid(true);
      } else {
        setAccessCode(null);
        setContact(result.data);
        setStep("confirmInfo");
      }
    } catch (error) {
      console.error("Error checking contact:", error);
      setAccessCode(null);
    }

    setAccessCode(null);
    setIsLoading(false);
  };

  return (
    <FormContainer ref={formRef} onSubmit={lookupContact}>
      <Label htmlFor="accessCode">Access Code</Label>
      <Input
        type="text"
        name="accessCode"
        id="accessCode"
        value={accessCode}
        className={service}
        onChange={(e) => setAccessCode(e.target.value)}
        required
      />
      <BtnContainer>
        <Btn disabled={isLoading} type="submit" className={service}>
          {isLoading ? <Spinner /> : "GET STARTED TODAY"}
        </Btn>
      </BtnContainer>
    </FormContainer>
  );
};

export default Access;
