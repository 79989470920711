import React from "react";
import ProcessSteps from "../../components/process-steps/ProcessSteps";
import { MainContainer, MainContent } from "./Debt.styles";
import Hero from "../../components/hero/Hero";

const Debt = () => {
  return (
    <MainContainer>
      <Hero text="SEIZE THE OPPORTUNITY FOR A BRIGHTER FUTURE WITH OUR HASSLE-FREE DEBT RESOLUTIONS PROGRAMS" />
      <MainContent>
        <ProcessSteps />
      </MainContent>
    </MainContainer>
  );
};

export default Debt;
