import React, { useState, useEffect, useContext } from "react";
import {
  ErrorMessage,
  ErrorMessageContainer,
  FormContainer,
  Heading1,
  Heading2,
  MainContainer,
  SubHeading,
} from "./Form.styles";
import { useParams } from "react-router-dom";
import Access from "./Access";
import ConfirmInfo from "./ConfirmInfo";
import ThankYou from "./ThankYou";
import { ServiceContext } from "../../contexts/service-context";
import TermAndConditions from "../modals/terms/TermAndConditions";

const Form = () => {
  const [step, setStep] = useState("access");
  const [displayTerms, setDisplayTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [contact, setContact] = useState({});
  const [firstName, setFirstName] = useState("");
  const [transfer, setTransfer] = useState("");

  const { service } = useContext(ServiceContext);

  const params = useParams();
  const uniqueUrl = params.id.toLocaleLowerCase();

  const handleDisplayTerms = () => {
    setDisplayTerms(true);
  };

  useEffect(() => {
    let timer;

    if (isInvalid) {
      timer = setTimeout(() => {
        setIsInvalid(false);
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [isInvalid]);

  return (
    <MainContainer className={service}>
      {(step === "access" || step === "confirmInfo") && (
        <>
          <Heading1>EXPEDITE YOUR LOAN APPROVAL NOW!</Heading1>
          <SubHeading>THIS WILL NOT AFFECT YOUR CREDIT SCORE.</SubHeading>
        </>
      )}

      {step === "access" && <Heading2>ENTER YOUR ACCESS CODE:</Heading2>}
      {step === "confirmInfo" && <Heading2>CONFIRM YOUR INFORMATION:</Heading2>}
      {step === "access" && (
        <Access
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          uniqueUrl={uniqueUrl}
          setIsInvalid={setIsInvalid}
          setContact={setContact}
          setStep={setStep}
          service={service}
        />
      )}

      {step === "confirmInfo" && (
        <ConfirmInfo
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          contact={contact}
          setContact={setContact}
          setTransfer={setTransfer}
          setFirstName={setFirstName}
          setStep={setStep}
          service={service}
          handleDisplayTerms={handleDisplayTerms}
        />
      )}

      {step === "thankYou" && (
        <ThankYou firstName={firstName} transfer={transfer} service={service} />
      )}

      {isInvalid && (
        <ErrorMessageContainer>
          <ErrorMessage>
            Invalid personal web address and/or access code!
          </ErrorMessage>
          <ErrorMessage>Please try again.</ErrorMessage>
        </ErrorMessageContainer>
      )}

      {displayTerms && <TermAndConditions setDisplayTerms={setDisplayTerms} />}
    </MainContainer>
  );
};

export default Form;
