import React, { useState, useEffect, useContext } from "react";
import { Bottom, MainContainer, MainContent, Middle } from "./Footer.styles";
import { ServiceContext } from "../../contexts/service-context";

const Footer = () => {
  const [currentYear, setCurrentYear] = useState("");

  const { service } = useContext(ServiceContext);

  function getCurrentYear() {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }

  useEffect(() => {
    const year = getCurrentYear();
    setCurrentYear(year);
  }, []);

  return (
    <>
      {(service === "debt" || service === "lending" || service === "tax") && (
        <MainContainer className={service}>
          <MainContent>
            <Middle>
              Your information is safe. The safety of your personal information
              is very important to us. All of the information you provide is
              kept in strict confidence.
            </Middle>

            <Bottom>
              &copy; {`${currentYear}`}{" "}
              <a href="https://emeraldgainlending.com">Emerald Gain Lending</a>{" "}
              All Rights Reserved.
            </Bottom>
          </MainContent>
        </MainContainer>
      )}
    </>
  );
};

export default Footer;
