import styled from "styled-components";
import HeroBackground from '../../assets/img/hero.png'

export const MainContainer = styled.div`
  width: 100%;
  position: relative;
  background-image: url(${HeroBackground});
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
`;

export const MainContent = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;

  @media only screen and (max-width: 1050px) {
    flex-direction: column;
    row-gap: 20px;
    text-align: center;
  }
`

export const LeftCol = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
`

export const RightCol = styled.div`

`
