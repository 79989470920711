import React from 'react';
import { MainContainer, MainContent } from './Layouts.styles';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { ThemeProvider } from 'styled-components';
import theme from '../theme'

const layouts = ({ children }) => {

  return (
    <ThemeProvider theme={theme}>
      <MainContainer>
        <Header />
        <MainContent>
          {children}
        </MainContent>
        <Footer />
      </MainContainer>
    </ThemeProvider>
  );
};

export default layouts;
