import React, { useContext } from "react";
import { LeftCol, MainContainer, MainContent, RightCol } from "./Hero.styles";
import Form from "../form/Form";
import { ServiceContext } from "../../contexts/service-context";

const Hero = ({ text }) => {
  const { service } = useContext(ServiceContext);

  return (
    <>
      {(service === "debt" || service === "lending" || service === "tax") && (
        <MainContainer>
          <MainContent>
            <LeftCol>{text}</LeftCol>
            <RightCol>
              <Form />
            </RightCol>
          </MainContent>
        </MainContainer>
      )}
    </>
  );
};

export default Hero;
