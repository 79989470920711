import styled from "styled-components";

export const MainContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
`;

export const MainContent = styled.div`
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
`

export const CloseIconContainer = styled.div`
width: 100%;
text-align: right;
margin-bottom: 5px;
transform: translate(-5px, -5px);
color: red;
font-weight: bold;
`

export const CloseIcon = styled.p`
display: inline;
cursor: pointer;
`

export const TextContentContainer = styled.div`
width: 100%;
/* height: calc(100vh - 165px); */
padding: 0 10px;
box-sizing: border-box;
`

export const TextContent = styled.div`
width: 100%;
height: 100%;
text-align: justify;
color: black;
line-height: 1.5;
color: white;
font-size: 12px;

a{
  color: black;
}
`
