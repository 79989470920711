import React, { useState } from "react";
import {
  Btn,
  CCHeading,
  CCSubHeading,
  ConfirmationContainer,
  ConfirmationContent,
  ConfirmationInput,
  Disclaimer,
  FormContainer,
  InputCol,
  InputRow,
  MainContainer,
} from "./Lend.styles";
import Spinner from "../spinner/Spinner";
import { functions, httpsCallable } from "../../config";
import axios from "axios";

const Confirmation = ({
  setTransfer,
  service,
  setContact,
  contact,
  setFirstName,
  setStep,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

  const handleChange = (e) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;

    setContact({
      ...contact,
      [targetName]: targetValue,
    });
  };

  const sendEmail = async (contact) => {
    const emailData = {
      service_id: EMAILJS_SERVICE_ID,
      template_id: EMAILJS_TEMPLATE_ID,
      user_id: EMAILJS_PUBLIC_KEY,
      template_params: {
        first: contact.first,
        middle: contact.middle,
        last: contact.last,
        address: contact.address,
        city: contact.city,
        st: contact.st,
        zip: contact.zip,
        email: contact.email,
        phone: contact.phone,
        ref: contact.ref,
      },
    };

    try {
      await axios.post(
        "https://api.emailjs.com/api/v1.0/email/send",
        emailData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    } catch (error) {
      // Log and throw any errors encountered
      console.error("Error sending email:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!contact) return;

    setIsLoading(true);

    const updateContact = httpsCallable(functions, "updateContact");

    updateContact({ contactObj: contact, id: contact.id })
      .then((result) => {
        // console.log(result.data)
      })
      .catch((error) => {
        const message = error.message;
        const details = error.details;
        console.error("Error calling updateContact:", message, details);
      });

    sendEmail(contact);
    setFirstName(contact.first);
    setTransfer(contact.transfer);
    setContact({});
    setIsLoading(false);
    setStep("thankYou");
  };

  return (
    <MainContainer>
      <ConfirmationContainer>
        <ConfirmationContent>
          <CCHeading>Verify Your Information</CCHeading>
          <CCSubHeading>
            Please verify your information, complete any empty fields and click
            Verify.
          </CCSubHeading>

          <FormContainer onSubmit={handleSubmit}>
            <InputRow>
              <InputCol>
                <ConfirmationInput
                  type="text"
                  name="first"
                  id="first"
                  value={contact.first}
                  onChange={handleChange}
                  required
                  placeholder="First Name (required)"
                />
              </InputCol>
              <InputCol>
                <ConfirmationInput
                  type="text"
                  name="last"
                  id="last"
                  value={contact.last}
                  onChange={handleChange}
                  required
                  placeholder="Last Name (required)"
                />
              </InputCol>
            </InputRow>
            <InputRow>
              <InputCol>
                <ConfirmationInput
                  type="email"
                  name="email"
                  id="email"
                  value={contact.email}
                  onChange={handleChange}
                  required
                  placeholder="Email (required)"
                />
              </InputCol>
              <InputCol>
                <ConfirmationInput
                  type="text"
                  name="phone"
                  id="phone"
                  value={contact.phone}
                  onChange={handleChange}
                  required
                  placeholder="Phone (required)"
                />
              </InputCol>
            </InputRow>

            <InputRow>
              <InputCol>
                <ConfirmationInput
                  type="text"
                  name="address"
                  id="address"
                  value={contact.address}
                  onChange={handleChange}
                  required
                  placeholder="Address (required)"
                />
              </InputCol>
              <InputCol>
                <ConfirmationInput
                  type="text"
                  name="city"
                  id="city"
                  value={contact.city}
                  onChange={handleChange}
                  required
                  placeholder="City (required)"
                />
              </InputCol>
            </InputRow>
            <InputRow>
              <InputCol>
                <ConfirmationInput
                  type="text"
                  name="st"
                  id="st"
                  value={contact.st}
                  onChange={handleChange}
                  required
                  placeholder="State (required)"
                />
              </InputCol>
              <InputCol>
                <ConfirmationInput
                  type="text"
                  name="zip"
                  id="zip"
                  value={contact.zip}
                  onChange={handleChange}
                  required
                  placeholder="Zip Code (required)"
                />
              </InputCol>
            </InputRow>
            <InputRow>
              <InputCol>
                <ConfirmationInput
                  type="text"
                  name="loanAmount"
                  id="loanAmount"
                  value={contact.loanAmount || ""}
                  onChange={handleChange}
                  required
                  placeholder="Loan Amount (required)"
                />
              </InputCol>
              <InputCol>
                <Btn className="VerifyBtn" disabled={isLoading} type="submit">
                  {isLoading ? <Spinner /> : "VERIFY"}
                </Btn>
              </InputCol>
            </InputRow>
          </FormContainer>
          <Disclaimer>
            By providing my contact information and clicking on the “Verify”
            button above, I acknowledge, agree, and provide express written
            consent to share my information with Emerald Gain Lending, its
            affiliates, partners and authorized third parties to deliver calls
            or text messages to me, including for marketing purposes to explore
            credit related products and services, using an automatic telephone
            dialing system or an artificial or pre-recorded voice to the phone
            number I have provided, even if it is currently listed on any
            federal, state, local, internal or corporate Do-Not-Call (“DNC”)
            List. I understand that I do not have to consent to receive these
            types of calls or text messages as a condition of purchasing any
            goods or services. Instead, I may call{" "}
            <a href="tel:+18886320709">(888) 632 - 0709</a> to continue my
            inquiry.
          </Disclaimer>
        </ConfirmationContent>
      </ConfirmationContainer>
    </MainContainer>
  );
};

export default Confirmation;
