export const expirationDates = {
  'jr-122923': '02/02/2024',
  'rs-122923': '02/02/2024',
  'jr-010924': '02/09/2024',
  'jr-011624': '02/16/2024',
  'rs-010924': '02/09/2024',
  'gj-020124': '02/16/2024',
  'jr-012324': '02/23/2024',
  'gj-020624': '02/28/2024',
  'rs-e020924': '03/08/2024',
  'rs-f021424': '03/15/2024',
  'gj-021424': '03/15/2024',
  'dms-021524': 'No Expiration',
  'xac-021624': '03/08/2024',
  'rs-h022824': '03/29/2024',
}

export const transfers = {
  '(877) 314-0802': '1',
  '(888) 855-1603': '2',
  '(888) 816-2009': '6',
  '(888) 632-0709': '12',
  '(866) 744-1121': '13',
}
