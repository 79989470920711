import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  padding: 30px 0 10px;

  &.lending {
    background-color: ${props => props.theme.colors.darkLending};
  }

  &.debt {
    background-color: ${props => props.theme.colors.darkDebt};
  }

  &.tax {
    background-color: ${props => props.theme.colors.darkTax};
  }
`

export const MainContent = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  text-align: center;
`

export const Top = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;

  a{
    color: inherit;
    text-decoration: none;
  }
`

export const Middle = styled.div`
  font-weight: bold;
  margin-bottom: 30px;
`

export const Bottom = styled.div`
  font-size: 12px;
  a{
    color: inherit;
    text-decoration: none;
    font-weight: bold;
  }
`
