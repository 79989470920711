import React, { useState, useContext } from "react";
import {
  MainContainer,
  MainContent,
  SectionNav,
  TabItem,
  TabsContainer,
  UserName,
} from "./Dashboard.styles";
import { UserContext } from "../../contexts/user-context";
import { auth, signOut } from "../../config";
import Upload from "./upload/Upload";
import Counts from "./counts/Counts";
import Search from "./search/Search";
import Leads from "./leads/Leads";

const AdminDashboard = () => {
  const [tab, setTab] = useState("search");

  const { user, setUser } = useContext(UserContext);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  return (
    <MainContainer>
      <UserName>
        [ {user.fname} {user.lname} ]
      </UserName>
      <SectionNav>
        <TabsContainer>
          {(user.role === "super-admin" || user.role === "admin") && (
            <TabItem
              onClick={() => setTab("home")}
              className={tab === "home" && "selected"}
            >
              Home
            </TabItem>
          )}
          <TabItem
            onClick={() => setTab("search")}
            className={tab === "search" && "selected"}
          >
            Search
          </TabItem>
          {user.role === "super-admin" && (
            <>
              <TabItem
                onClick={() => setTab("counts")}
                className={tab === "counts" && "selected"}
              >
                Counts
              </TabItem>

              <TabItem
                onClick={() => setTab("upload")}
                className={tab === "upload" && "selected"}
              >
                Upload
              </TabItem>
            </>
          )}
          <TabItem onClick={handleLogout}>Logout</TabItem>
        </TabsContainer>
      </SectionNav>
      <MainContent>{tab === "home" && <Leads />}</MainContent>
      <MainContent>{tab === "search" && <Search />}</MainContent>
      <MainContent>{tab === "counts" && <Counts />}</MainContent>
      <MainContent>{tab === "upload" && <Upload />}</MainContent>
    </MainContainer>
  );
};

export default AdminDashboard;
