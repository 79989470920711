import React, { useContext } from "react";
import { Logo, MainContainer, MainContent } from "./Header.styles";
import EmeraldGainLendingLogo from "../../assets/img/EmeraldGain-Lending-Logo.png";
import EmeraldGainDebtLogo from "../../assets/img/EmeraldGain-Debt-Logo.png";
import EmeraldGainTaxLogo from "../../assets/img/EmeraldGain-Tax-Logo.png";
import { ServiceContext } from "../../contexts/service-context";

const Header = () => {
  const { service } = useContext(ServiceContext);

  const handleLogo = (service) => {
    if(service === "lending") {
      return EmeraldGainLendingLogo
    }
    if(service === "debt") {
      return EmeraldGainDebtLogo
    }
    if(service === "tax") {
      return EmeraldGainTaxLogo
    }
  }

  return (
    (service === "debt" || service === "lending" || service === "tax") && (
      <MainContainer className={service}>
        <MainContent>
          <Logo src={handleLogo(service)} alt="Emerald Gain logo" />
        </MainContent>
      </MainContainer>
    )
  );
};
export default Header;
