import React, { useState, useEffect, useRef } from "react";
import {
  Btn,
  Description,
  DescriptionRow,
  ErrorMessage,
  ErrorMessageContainer,
  FeatureDescription,
  FeatureItem,
  FeatureRow,
  FeatureRowBottom,
  FeatureRowTitle,
  FeatureRowTop,
  FormContainer,
  FormTitle,
  Heading,
  Hero,
  HeroContent,
  Icon,
  Img,
  Input,
  MainContainer,
  MainContent,
  SubHeading,
} from "./Lend.styles";
import { httpsCallable, functions } from "../../config";
import Spinner from "../spinner/Spinner";
import Envelope from "../../assets/img/Envelope.png";
import { FaPhoneVolume } from "react-icons/fa6";
import { BiSolidMessageSquareCheck } from "react-icons/bi";

const Access = ({ uniqueUrl, service, setContact, setStep }) => {
  const [accessCode, setAccessCode] = useState(null);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef(null);

  const lookupContact = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    formRef.current.reset();

    if (!uniqueUrl || !accessCode || !service) {
      setAccessCode(null);
      setIsInvalid(true);
      setIsLoading(false);
      return;
    }

    const checkContact = httpsCallable(functions, "lookupContact");

    try {
      const result = await checkContact({
        accessCode: accessCode,
        param: uniqueUrl,
        service: service,
      });

      formRef.current.reset();

      if (result.data === false) {
        setAccessCode(null);
        setIsInvalid(true);
      } else {
        setAccessCode(null);
        setContact(result.data);
        setStep("confirmInfo");
      }
    } catch (error) {
      console.error("Error checking contact:", error);
      setAccessCode(null);
    }

    setAccessCode(null);
    setIsLoading(false);
  };

  useEffect(() => {
    let timer;

    if (isInvalid) {
      timer = setTimeout(() => {
        setIsInvalid(false);
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [isInvalid]);

  return (
    <MainContainer>
      <Hero>
        <HeroContent>
          <Heading>Congratulations, You’re Pre-Approved!</Heading>
          <SubHeading>
            Take advantage of your Emerald Gain Lending loan offer.
          </SubHeading>
          <SubHeading>
            For immediate service, call{" "}
            <a href="tel:+18886320709">(888) 632 - 0709</a>.
          </SubHeading>
        </HeroContent>
      </Hero>
      <MainContent>
        <Img className="envelope" src={Envelope} alt="Emerald Gain envelope" />

        <FormTitle>
          <span>It’s easy to get started —</span> just enter the Access Code
          from the letter we sent you:
        </FormTitle>

        <FormContainer ref={formRef} onSubmit={lookupContact}>
          {isInvalid ? (
            <ErrorMessageContainer>
              <ErrorMessage>
                Invalid personal web address and/or access code!
              </ErrorMessage>
              <ErrorMessage>Please try again.</ErrorMessage>
            </ErrorMessageContainer>
          ) : (
            <>
              <Input
                type="text"
                name="accessCode"
                id="accessCode"
                placeholder="Access Code"
                value={accessCode}
                className={service}
                onChange={(e) => setAccessCode(e.target.value)}
                required
              />
              <Btn disabled={isLoading} type="submit">
                {isLoading ? <Spinner /> : "get my loan"}
              </Btn>
            </>
          )}
        </FormContainer>

        <Description>
          This will <span>NOT</span> impact your credit score.
        </Description>

        <DescriptionRow>
          <Icon>
            <FaPhoneVolume />
          </Icon>

          <Description>
            <a href="tel:+18886320709">Click</a> to speak to a Loan Consultant
            and expedite your loan approval now.
          </Description>
        </DescriptionRow>

        <FeatureRow>
          <FeatureRowTop>
            <FeatureRowTitle>Get your simple solution and …</FeatureRowTitle>
          </FeatureRowTop>
          <FeatureRowBottom>
            <FeatureItem>
              <Icon className="featureIcon">
                <BiSolidMessageSquareCheck />
              </Icon>
              <FeatureDescription>
                Access FAST relief with funds
              </FeatureDescription>
              <FeatureDescription>
                wired directly to your account
              </FeatureDescription>
            </FeatureItem>

            <FeatureItem>
              <Icon className="featureIcon">
                <BiSolidMessageSquareCheck />
              </Icon>
              <FeatureDescription>Have more money</FeatureDescription>
              <FeatureDescription>each month</FeatureDescription>
            </FeatureItem>

            <FeatureItem>
              <Icon className="featureIcon">
                <BiSolidMessageSquareCheck />
              </Icon>
              <FeatureDescription>Save thousands of</FeatureDescription>
              <FeatureDescription>dollars overall</FeatureDescription>
            </FeatureItem>

            <FeatureItem>
              <Icon className="featureIcon">
                <BiSolidMessageSquareCheck />
              </Icon>
              <FeatureDescription>Simplify your budget with</FeatureDescription>
              <FeatureDescription>one monthly payment</FeatureDescription>
            </FeatureItem>
          </FeatureRowBottom>
        </FeatureRow>
      </MainContent>
    </MainContainer>
  );
};

export default Access;
