import React from "react";
import { LineWave } from "react-loader-spinner";
import { SpinnerContainer } from "./Spinner.styles";

const Spinner = ({ largeContainer }) => {
  return (
    <SpinnerContainer className={largeContainer && "full"}>
      <LineWave
        height={largeContainer ? "300" : "100"}
        width={largeContainer ? "300" : "100"}
        color="#0F3442"
        ariaLabel="line-wave"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        firstLineColor=""
        middleLineColor=""
        lastLineColor=""
      />
    </SpinnerContainer>
  );
};

export default Spinner;
