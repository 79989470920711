import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../contexts/user-context";
import {
  db,
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  updateDoc,
  doc,
} from "../../../config";
import {
  HeadRow,
  MainContainer,
  MainContent,
  QueueValue,
  Value,
} from "./Leads.styles";
import LeadItem from "./LeadItem";
import { functions, httpsCallable } from "../../../config";
import NewLeadSFX from "../../../assets/sfx/new_lead_sfx.mp3";

const Leads = () => {
  const [leads, setLeads] = useState([]);
  const [agents, setAgents] = useState([]);
  const [queueUser, setQueueUser] = useState(false);
  const [inQueue, setInQueue] = useState([]);
  const [newLeads, setNewLeads] = useState([]);
  const [viewFilter, setViewFilter] = useState("all");

  const { user } = useContext(UserContext);

  const fetchAgents = async () => {
    try {
      const getAllAgents = httpsCallable(functions, "getAllAgents");

      const result = await getAllAgents();
      setAgents(result.data.users);
    } catch (error) {
      console.error(error.message);
    }
  };

  const assignAgent = (documentId, agentValue) => {
    const assignAgentFunction = httpsCallable(functions, "assignAgent");

    assignAgentFunction({ id: documentId, agent: agentValue })
      .then((result) => {
        // console.log(result.data.message);
      })
      .catch((error) => {
        console.error(`Error: ${error.message}`);
      });
  };

  const handleViewFilterChange = (e) => {
    setViewFilter(e.target.value);
  };

  useEffect(() => {
    const authorized =
      user && (user.role === "admin" || user.role === "super-admin");
    if (!authorized) return;

    if (user.fname === "Lead") {
      setQueueUser(true);
    } else {
      setQueueUser(false);
    }

    fetchAgents();

    const leadsQuery = query(
      collection(db, "contacts"),
      where("isLead", "==", true),
      orderBy("updatedAt", "desc")
    );

    // Subscribe to Firestore updates
    const unsubscribe = onSnapshot(
      leadsQuery,
      (snapshot) => {
        const newLeads = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLeads(newLeads);
      },
      (err) => {
        console.error(`Encountered error: ${err}`);
      }
    );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    const statusDocRef = doc(db, "status", "newLeadStatus");

    const unsubscribe = onSnapshot(
      statusDocRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          if (data.new === true) {
            // Play the MP3 file
            const audio = new Audio(NewLeadSFX);
            audio
              .play()
              .then(() => {
                // After playing the sound, update the document
                updateDoc(statusDocRef, { new: false }).catch((error) => {
                  console.error("Error updating document:", error);
                });
              })
              .catch((error) => {
                console.error("Error playing sound:", error);
              });
          }
        } else {
          console.log("Document does not exist!");
        }
      },
      (error) => {
        console.error("Encountered error: ", error);
      }
    );

    // Cleanup function to unsubscribe from the listener on component unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!leads || leads.length < 1) return;

    const newLeads = leads.filter(
      (lead) => !lead.agentId && lead.phone && lead.email
    );

    setInQueue(newLeads);
  }, [leads]);

  useEffect(() => {
    if (!leads) return;

    const filteredNewLeads = leads.filter((l) => l.isNew);
    setNewLeads(filteredNewLeads);
  }, [leads]);

  return (
    <MainContainer>
      <MainContent className={queueUser && "queueUser"}>
        <HeadRow>
          {queueUser ? (
            <>
              <QueueValue>Time</QueueValue>
              <QueueValue>Name</QueueValue>
            </>
          ) : (
            <>
              <Value>Time</Value>
              <Value>Access Code</Value>
              <Value>Name</Value>
              <Value>Phone</Value>
              <Value>Email</Value>
              <Value>Debt</Value>
              <Value>Source</Value>
              <Value>Service</Value>
              <Value>Agent</Value>
              <Value>
                Action{" "}
                <select
                  name="viewFilter"
                  id="viewFilter"
                  onChange={handleViewFilterChange}
                >
                  <option value="all">All</option>
                  <option value="new">New</option>
                </select>
              </Value>
            </>
          )}
        </HeadRow>

        {queueUser
          ? inQueue.map((lead) => {
              return (
                <LeadItem
                  key={lead.id}
                  lead={lead}
                  agents={agents}
                  queueUser={queueUser}
                  assignAgent={assignAgent}
                />
              );
            })
          : (viewFilter === "new" ? newLeads : leads).map((lead) => (
              <LeadItem
                key={lead.id}
                lead={lead}
                agents={agents}
                assignAgent={assignAgent}
              />
            ))}
      </MainContent>
    </MainContainer>
  );
};

export default Leads;
