import React, { useState, useEffect, useMemo } from 'react';


export const ServiceContext = React.createContext();

const ServiceProvider = ({ children }) => {
  const [service, setService] = useState(null);

  useEffect(() => {
    const url = window.location.href;
    const segments = url.split('/');
    const segmentOfInterest = segments[3];
    let segmentOfInterestLC = segmentOfInterest.toLocaleLowerCase()
    if (segmentOfInterestLC === 'lending' || segmentOfInterestLC === 'debt' || segmentOfInterestLC === 'tax' || segmentOfInterestLC === 'lend') {
      if (segmentOfInterestLC === 'lend') {
        segmentOfInterestLC = 'lending'
      }
      setService(segmentOfInterestLC);
    }
  }, [])

  const value = useMemo(() => ({ service, setService }), [service]);

  return (
    <ServiceContext.Provider value={value}>
      {children}
    </ServiceContext.Provider>
  );
};

export default ServiceProvider;
