import React, { useState, useEffect } from "react";
import {
  Footer,
  FooterText,
  Header,
  HeaderCTA,
  HeaderTopLeft,
  HeaderTopRight,
  Img,
} from "./Lend.styles";
import EmeraldGainLendingLogo from "../../assets/img/EmeraldGain-Lending-Logo.png";

export const HeaderSection = () => {
  return (
    <Header>
      <HeaderTopLeft>
        <Img src={EmeraldGainLendingLogo} alt="Emerald Gain logo" />
      </HeaderTopLeft>
      <HeaderTopRight className="pipi">
        <a href="tel:+18886320709">
          <HeaderCTA>
            <p>TALK TO A LIVE AGENT</p>
            <p>(888) 632 - 0709</p>
          </HeaderCTA>
        </a>
      </HeaderTopRight>
    </Header>
  );
};

export const FooterSection = () => {
  const [currentYear, setCurrentYear] = useState("");

  function getCurrentYear() {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }

  useEffect(() => {
    const year = getCurrentYear();
    setCurrentYear(year);
  }, []);
  return (
    <Footer>
      <Img
        className="footerLogo"
        src={EmeraldGainLendingLogo}
        alt="Emerald Gain logo"
      />
      <FooterText>
        Emerald Gain Lending &copy; {`${currentYear}`}. All Rights Reserved.
      </FooterText>
    </Footer>
  );
};
