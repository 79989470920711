import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  max-width: 800px;
  min-width: 300px;
  padding: 20px;
  border: 1px solid ${props => props.theme.colors.lightLending};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 2;
  position: relative;
  text-align: center;

  &.debt {
    border-color: ${props => props.theme.colors.lightDebt};
  }

  &.lending {
    border-color: ${props => props.theme.colors.lightLending};
  }

  &.tax {
    border-color: ${props => props.theme.colors.lightTax};
  }
`

export const Heading1 = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`

export const SubHeading = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`

export const Heading2 = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 20px;
`

export const FormContainer = styled.form`
  width: 100%;
  text-align: left;
`

export const InputRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;

  @media only screen and (max-width: 470px) {
    flex-direction: column;
  }
`

export const InputCol = styled.div`
  width: 100%;

  &.termsInputCols {
  width: 20px;
  margin-right: 5px;

  input {
    margin-left: 0;
    cursor: pointer;
    transform: translateY(1px);
    border-radius: 0px;
  }

}
`

export const Input = styled.input`
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  width: 100%;
  height: 33px;
  border: 1px solid gray;
  margin-bottom: 10px;
  padding: 0 5px;
  font-size: 16px;
  border: 1px solid ${props => props.theme.colors.lightLending};
  border-radius: 0px;

  &.debt {
    border-color: ${props => props.theme.colors.lightDebt};
  }

  &.lending {
    border-color: ${props => props.theme.colors.lightLending};
  }

  &.tax {
    border-color: ${props => props.theme.colors.lightTax};
  }
`

export const Label = styled.label`
  width: 100%;
  display: block;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
`

export const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5px;
`

export const Btn = styled.button`
  width: 100%;
  height: 35px;
  border: 1px solid ${props => props.theme.colors.lightLending};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  color: white;
  background-color: ${props => props.theme.colors.darkLending};

  &.debt {
    border-color: ${props => props.theme.colors.lightDebt};
    background-color: ${props => props.theme.colors.darkDebt};
  }

  &.lending {
    border-color: ${props => props.theme.colors.lightLending};
    background-color: ${props => props.theme.colors.darkLending};
  }

  &.tax {
    border-color: ${props => props.theme.colors.lightTax};
    background-color: ${props => props.theme.colors.darkTax};
  }

  &:hover {
    background-color: black
  }
`

export const ErrorMessageContainer = styled.div`
  width: 90%;
  margin: auto;
  text-align: center;
  font-weight: bold;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 25px;

`

export const ErrorMessage = styled.div`
  color: red;
  &:not(:last-child) {
    margin-bottom: 10px;
}
`

export const TY = styled.div`
  width: 100%;
  text-align: center;

  &.top {
    font-size: 18px;
    font-weight: bold;

    &.lending {
      color: ${props => props.theme.colors.lightLending};
    }

    &.debt {
      color: ${props => props.theme.colors.lightDebt};
    }

    &.tax {
      color: ${props => props.theme.colors.lightTax};
    }
  }

  &.small {

  }

  &.middle {
    font-size: 18px;
    font-weight: bold;
  }

  &.bottom {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 5px;
    a{
      text-decoration: none;
      color: inherit;
    }

    &.lending {
      color: ${props => props.theme.colors.lightLending};
    }

    &.debt {
      color: ${props => props.theme.colors.lightDebt};
    }

    &.tax {
      color: ${props => props.theme.colors.lightTax};
    }
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const Checkbox = styled.input`
  box-sizing: border-box;
  outline: none;
  width: 13px;
  height: 13px;
  border: 1px solid gray;
  border-radius: 0px;

  &.debt {
    border-color: ${props => props.theme.colors.lightDebt};
  }

  &.lending {
    border-color: ${props => props.theme.colors.lightLending};
  }

  &.tax {
    border-color: ${props => props.theme.colors.lightTax};
  }
`

export const Disclaimer = styled.div`
  font-size: 14px;
  color: #8d9ba0;
  /* text-align: justify; */
  display: flex;
  align-items: center;
  line-height: 1.4;
  transform: translateY(-1px);

  span.termsLink {
    color: red;
    cursor: pointer;
  }
`;
