import styled from "styled-components";

export const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  div {
    transform: translate(20px, -16px);
  }

  &.full {
    height: 100vh;
    align-items: center;

    div {
    transform: translate(60px, -16px);
  }

  }
`;
