import React, { useEffect, useState, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import Layouts from "./layouts/layouts";
import Home from './pages/home/Home';
import Admin from './pages/admin/Admin';
import UserProvider from './contexts/user-context';
import ServiceProvider from './contexts/service-context';
import Spinner from './components/spinner/Spinner'
import { functions, httpsCallable } from './config'
import Lending from './pages/lending/Lending';
import Debt from './pages/debt/Debt';
import Tax from './pages/tax/Tax';
import Lend from './pages/lend/Lend';

function App() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkIP = async () => {
    try {
      const checkIPFunction = httpsCallable(functions, 'checkIP');

      const response = await checkIPFunction();

      if (response.data.status === 'success') {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    } catch (error) {
      console.error('Error checking IP:', error);
      setIsAuthorized(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkIP();
  }, []);

  if (isLoading) {
    return <Spinner largeContainer={true} />;
  }

  return (
    <UserProvider>
      <ServiceProvider>
        <Layouts>
          <Routes>
            <Route path="/lending/:id" element={<Lending />} />
            <Route path="/debt/:id" element={<Debt />} />
            <Route path="/tax/:id" element={<Tax />} />
            <Route path="/lend/:id" element={<Lend />} />
            <Route path="*" element={<Home />} />

            {
              isAuthorized && <Route path="/admin" element={<Admin />} />
            }

          </Routes>
        </Layouts>
      </ServiceProvider>
    </UserProvider>
  );
}

export default App;
