import React, { useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import Access from '../../components/lend/Access';
import Confirmation from '../../components/lend/Confirmation';
import ThankYou from '../../components/lend/ThankYou';
import { FooterSection, HeaderSection } from '../../components/lend/HeaderFooter';
import { ServiceContext } from '../../contexts/service-context';

const Lend = () => {
  const [step, setStep] = useState("access");
  const [contact, setContact] = useState({});
  const [firstName, setFirstName] = useState("");
  const [transfer, setTransfer] = useState("");

  const { service } = useContext(ServiceContext);

  const params = useParams();
  const uniqueUrl = params.id.toLocaleLowerCase();

  return (
    <>
      <HeaderSection />
      {step === 'access' && <Access setStep={setStep} uniqueUrl={uniqueUrl} service={service} setContact={setContact} />}
      {step === 'confirmInfo' && <Confirmation setTransfer={setTransfer} setFirstName={setFirstName} setStep={setStep} uniqueUrl={uniqueUrl} service={service} setContact={setContact} contact={contact} />}
      {step === 'thankYou' && <ThankYou firstName={firstName} transfer={transfer} />}
      <FooterSection />
    </>
  );
};

export default Lend;
