const theme = {
  colors: {
    darkLending: '#44064A',
    lightLending: '#501055',
    darkDebt: '#120845',
    lightDebt: '#2A3F63',
    darkTax: '#448524',
    lightTax: '#4C793F',
  },
};

export default theme;
