import React, { useState } from "react";
import {
  Btn,
  BtnContainer,
  Checkbox,
  Disclaimer,
  FormContainer,
  Input,
  InputCol,
  InputRow,
  Label,
} from "./Form.styles";
import Spinner from "../spinner/Spinner";
import { functions, httpsCallable } from "../../config";
import axios from "axios";

const ConfirmInfo = ({
  isLoading,
  setIsLoading,
  contact,
  setContact,
  setTransfer,
  setFirstName,
  setStep,
  service,
  handleDisplayTerms,
}) => {
  const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
  const SECURED_URL = process.env.REACT_APP_SECURED_URL;

  const handleChange = (e) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;

    setContact({
      ...contact,
      [targetName]: targetValue,
    });
  };

  const sendEmail = async (contact) => {
    const emailData = {
      service_id: EMAILJS_SERVICE_ID,
      template_id: EMAILJS_TEMPLATE_ID,
      user_id: EMAILJS_PUBLIC_KEY,
      template_params: {
        first: contact.first,
        middle: contact.middle,
        last: contact.last,
        address: contact.address,
        city: contact.city,
        st: contact.st,
        zip: contact.zip,
        email: contact.email,
        phone: contact.phone,
        ref: contact.ref,
      },
    };

    try {
      await axios.post(
        "https://api.emailjs.com/api/v1.0/email/send",
        emailData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    } catch (error) {
      // Log and throw any errors encountered
      console.error("Error sending email:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!contact) return;

    setIsLoading(true);

    const updateContact = httpsCallable(functions, "updateContact");

    updateContact({ contactObj: contact, id: contact.id })
      .then((result) => {
        // console.log(result.data)
      })
      .catch((error) => {
        const message = error.message;
        const details = error.details;
        console.error("Error calling updateContact:", message, details);
      });

    sendEmail(contact);
    setFirstName(contact.first);
    setTransfer(contact.transfer);
    setContact({});
    setIsLoading(false);
    setStep("thankYou");
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <InputRow>
        <InputCol>
          <Label htmlFor="first">First Name</Label>
          <Input
            className={service}
            type="text"
            name="first"
            id="first"
            value={contact.first}
            onChange={handleChange}
            required
          />
        </InputCol>
        <InputCol>
          <Label htmlFor="middle">Middle Name</Label>
          <Input
            className={service}
            type="text"
            name="middle"
            id="middle"
            value={contact.middle}
            onChange={handleChange}
          />
        </InputCol>
        <InputCol>
          <Label htmlFor="last">Last Name</Label>
          <Input
            className={service}
            type="text"
            name="last"
            id="last"
            value={contact.last}
            onChange={handleChange}
            required
          />
        </InputCol>
      </InputRow>
      <InputRow>
        <InputCol>
          <Label htmlFor="email">Email</Label>
          <Input
            className={service}
            type="email"
            name="email"
            id="email"
            value={contact.email}
            onChange={handleChange}
            required
          />
        </InputCol>
        <InputCol>
          <Label htmlFor="phone">Phone</Label>
          <Input
            className={service}
            type="text"
            name="phone"
            id="phone"
            value={contact.phone}
            onChange={handleChange}
            required
          />
        </InputCol>
      </InputRow>
      <Label htmlFor="address">Address</Label>
      <Input
        className={service}
        type="text"
        name="address"
        id="address"
        value={contact.address}
        onChange={handleChange}
        required
      />
      <InputRow>
        <InputCol>
          <Label htmlFor="city">City</Label>
          <Input
            className={service}
            type="text"
            name="city"
            id="city"
            value={contact.city}
            onChange={handleChange}
            required
          />
        </InputCol>
        <InputCol>
          <Label htmlFor="st">State</Label>
          <Input
            className={service}
            type="text"
            name="st"
            id="st"
            value={contact.st}
            onChange={handleChange}
            required
          />
        </InputCol>
        <InputCol>
          <Label htmlFor="zip">Zip Code</Label>
          <Input
            className={service}
            type="text"
            name="zip"
            id="zip"
            value={contact.zip}
            onChange={handleChange}
            required
          />
        </InputCol>
      </InputRow>
      <Disclaimer>
        <InputCol className="termsInputCols">
          <Checkbox
            required
            type="checkbox"
            id="agreeCheckbox"
            name="agreeCheckbox"
          />
        </InputCol>
        <InputCol>
          By submitting this form, I acknowledge and agree to the{" "}
          <span onClick={handleDisplayTerms} className="termsLink">
            Terms of Use
          </span>
          .
        </InputCol>
      </Disclaimer>
      <BtnContainer>
        <Btn disabled={isLoading} type="submit" className={service}>
          {isLoading ? <Spinner /> : "SUBMIT"}
        </Btn>
      </BtnContainer>
    </FormContainer>
  );
};

export default ConfirmInfo;
