import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
`

export const Header = styled.div`
  width: 80%;
  height: 170px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 850px) {
    flex-direction: column;
    height: 100%;
    padding: 15px 0;
    row-gap: 10px;
  }
`

export const Hero = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.darkLending};
`

export const HeroContent = styled.div`
  width: 80%;
  margin: auto;
  padding: 60px 0;
  padding-bottom: 105px;
  color: white;
  text-align: center;
`

export const Heading = styled.div`
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 15px;

  @media only screen and (max-width: 850px) {
    font-size: 35px;
  }
`

export const SubHeading = styled.div`
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 10px;

  a{
    color: white;
    text-decoration: none;
  }
`

export const HeaderTopLeft = styled.div`

`

export const HeaderTopRight = styled.div`
a{
  text-decoration: none;
}
`

export const Img = styled.img`
  height: 130px;

  &.envelope {
    height: 140px;
    transform: translateY(-95px);
  }

  &.footerLogo {
    height: 80px;
    margin-bottom: 5px;
  }

  &.symbol {
    height: 80px;
    margin: 30px auto;
  }
`

export const HeaderCTA = styled.div`
  background-color: black;
  padding: 20px;
  color: white;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;

  p{
    margin: 0;
  }
  a{
    color: white;
  }

  @media only screen and (max-width: 850px) {
    width: 100%;
  }
`

export const MainContent = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const FormTitle = styled.div`
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 35px;
  text-align: center;

  span {
    font-weight: bold;
  }
`

export const FormContainer = styled.form`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
`

export const Input = styled.input`
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  width: 80%;
  max-width: 700px;
  height: 48px;
  font-size: 20px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid black;
  outline: none;
  margin-bottom: 20px;

  @media only screen and (max-width: 450px) {
    width: 100%;
  }
`

export const Btn = styled.button`
  height: 30px;
  background-color: black;
  border: 1px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  font-size: 18px;
  color: white;
  text-transform: uppercase;
  padding: 23px 35px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 25px;

  &:hover {
    border-color: purple;
    background-color: ${props => props.theme.colors.darkLending};
  }

  &.VerifyBtn {
    width: 100%;
    height: 41px;
    padding: 0;
  }
`

export const Description = styled.div`
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;

  span {
    font-weight: bold;
  }

  a {
    color: ${props => props.theme.colors.darkLending};
  }
`

export const DescriptionRow = styled.div`
  width: 370px;
  display: flex;
  justify-content: center;
`

export const Icon = styled.div`
  font-size: 35px;
  color: ${props => props.theme.colors.darkLending};
  transform: translateY(7px);

  &.featureIcon {
    font-size: 45px;
    margin-bottom: 10px;
  }
`

export const FeatureRow = styled.div`
  width: 100%;
  margin: 30px auto;
  margin-bottom: 30px;
  text-align: center;
`

export const FeatureRowTop = styled.div`
  width: 100%;
`

export const FeatureRowTitle = styled.div`
  width: 100%;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 30px;
`

export const FeatureRowBottom = styled.div`
  width: 100%;
  max-width: 1150px;
  margin: auto;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 15px;

  @media only screen and (max-width: 1580px) {
    grid-template-columns: 1fr 1fr;
    max-width: 570px;
  }

  @media only screen and (max-width: 840px) {
    grid-template-columns: 1fr;
  }
`

export const FeatureItem = styled.div`
  width: 270px;
  height: 140px;
  padding: 10px;
  text-align: center;
  background-color: white;
  border-radius: 5px;
`

export const FeatureDescription = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
`

export const Footer = styled.div`
  width: 100%;
  border-top: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 0 20px;
  font-size: 14px;
  font-weight: 500;
`

export const FooterText = styled.div`
width: 100%;
text-align: center;
`

export const ErrorMessageContainer = styled.div`
  width: 100%;
  height: 141px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 25px;

`

export const ErrorMessage = styled.div`
  color: ${props => props.theme.colors.darkLending};
  &:not(:last-child) {
    margin-bottom: 10px;
}
`

// Confirmation

export const ConfirmationContainer = styled.div`
  width: 80%;
  max-width: 1400px;
  background-color: white;
  margin: 40px auto 50px;
  padding: 50px 0;
`

export const ConfirmationContent = styled.div`
  width: 80%;
  margin: auto;
`

export const CCHeading = styled.div`
  width: 100%;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 10px;
`

export const CCSubHeading = styled.div`
font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
`

export const InputRow = styled.div`
width: 100%;
display: flex;
column-gap: 15px;
row-gap: 20px;

@media only screen and (max-width: 800px) {
    flex-direction: column;
    row-gap: 0;
    column-gap: 0;
  }
`

export const InputCol = styled.div`
width: 100%;
`

export const Disclaimer = styled.div`
line-height: 1.5;
text-align: justify;
margin-top: 10px;
a{
  color: black;
  font-weight: 500;
  text-decoration: none;
}
`

export const ConfirmationInput = styled.input`
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  width: 100%;
  height: 40px;
  font-size: 16px;
  padding: 0 15px;
  border-radius: 5px;
  border: 1px solid black;
  outline: none;
  margin-bottom: 20px;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`

export const TYContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const TYHeading = styled.div`
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
`

export const TYSubHeading = styled.div`
  font-size: 30px;
  text-align: center;
  line-height: 1.5;

  a{
    color: ${props => props.theme.colors.lightLending};
  }
`
