import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Btn,
  BtnContainer,
  ContactContainer,
  ContactValueRow,
  ErrMsg,
  Input,
  Label,
  MainContainer,
  MainContent,
} from "./Search.styles";
import { functions, httpsCallable } from "../../../config";
import Spinner from "../../spinner/Spinner";
import { expirationDates, transfers } from "../../../helpers";
import { UserContext } from "../../../contexts/user-context";

const Search = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [contact, setContact] = useState(null);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [tempAccessCode, setTempAccessCode] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [transferNum, setTransferNum] = useState("");

  const accessFormRef = useRef();
  const updateFormRef = useRef();
  const { user } = useContext(UserContext);

  const handleSearch = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    if (!accessCode) {
      setAccessCode("");
      setPhone("");
      setEmail("");
      accessFormRef.current.reset();
      setIsLoading(false);
      return;
    }

    setTempAccessCode(accessCode);
    setIsInvalid(false);
    setContact(null);

    const searchContact = httpsCallable(functions, "searchContact");
    const uppercaseAccessCode = accessCode.toUpperCase();

    try {
      const result = await searchContact({
        accessCode: uppercaseAccessCode,
      });

      if (result.data === false) {
        setIsInvalid(true);
        setAccessCode("");
        setPhone("");
        setEmail("");
        accessFormRef.current.reset();
      } else {
        setAccessCode("");
        setContact(result.data);
        accessFormRef.current.reset();
        setPhone(result.data.phone);
        setEmail(result.data.email);
      }
    } catch (error) {
      console.error("Error checking contact:", error);
      setAccessCode("");
      setPhone("");
      setEmail("");
      accessFormRef.current.reset();
    }

    // accessFormRef.current.reset();
    // setAccessCode("");
    // setPhone("");
    // setEmail("");
    setIsLoading(false);
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    if (!contact.id) return;

    const updateLead = httpsCallable(functions, "updateLead");

    updateLead({ id: contact.id, phone: phone, email: email, agent: user.id })
      .then((result) => {
        setContact(null);
        setAccessCode("");
        setPhone("");
        setEmail("");
        accessFormRef.current.reset();
        updateFormRef.current.reset();
      })
      .catch((error) => {
        setContact(null);
        setAccessCode("");
        setPhone("");
        setEmail("");
        accessFormRef.current.reset();
        updateFormRef.current.reset();
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (!contact) return;

    const d = expirationDates[contact.dataset];
    setExpirationDate(d);

    const t = transfers[contact.transfer];
    setTransferNum(t);
  }, [contact]);

  return (
    <MainContainer>
      <MainContent>
        <form onSubmit={handleSearch} ref={accessFormRef}>
          <Label htmlFor="accessCode">Access Code</Label>
          <Input
            type="text"
            id="accessCode"
            name="accessCode"
            value={accessCode}
            onChange={(e) => setAccessCode(e.target.value)}
          />
          <BtnContainer>
            <Btn className={isLoading && "disabled"} disabled={isLoading}>
              {isLoading ? <Spinner /> : "Search"}
            </Btn>
          </BtnContainer>
        </form>

        {isInvalid && (
          <ErrMsg>
            No file found with access code: <span>{tempAccessCode}</span>
          </ErrMsg>
        )}

        {contact && (
          <ContactContainer>
            <ContactValueRow>
              <div>Access Code:</div>
              <div>{contact.ref}</div>
            </ContactValueRow>
            <ContactValueRow>
              <div>Name:</div>
              <div>
                {contact.first} {contact.middle} {contact.last}
              </div>
            </ContactValueRow>
            <ContactValueRow>
              <div>Address 1:</div>
              <div>{contact.address}</div>
            </ContactValueRow>
            <ContactValueRow>
              <div>Address 2:</div>
              <div>
                {contact.city}, {contact.st} {contact.zip}
              </div>
            </ContactValueRow>
            <ContactValueRow>
              <div>Service:</div>
              <div>{contact.service}</div>
            </ContactValueRow>
            <ContactValueRow>
              <div>DEBT:</div>
              <div>{contact.debt ? contact.debt : "-"}</div>
            </ContactValueRow>
            <ContactValueRow>
              <div>Expiration:</div>
              <div>{expirationDate}</div>
            </ContactValueRow>
            {contact.loanAmount && (
              <ContactValueRow>
                <div>Requested Loan Amount:</div>
                <div>{contact.loanAmount}</div>
              </ContactValueRow>
            )}
            <ContactValueRow>
              <div>Transfer:</div>
              <div>{transferNum}</div>
            </ContactValueRow>

            <form ref={updateFormRef} onSubmit={handleUpdate}>
              <Label htmlFor="email">Email</Label>
              <Input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Label htmlFor="phone">Phone</Label>
              <Input
                type="text"
                id="phone"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <BtnContainer>
                <Btn className={isLoading && "disabled"} disabled={isLoading}>
                  {isLoading ? <Spinner /> : "Update"}
                </Btn>
              </BtnContainer>
            </form>
          </ContactContainer>
        )}
      </MainContent>
    </MainContainer>
  );
};

export default Search;
