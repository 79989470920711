import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Btn,
  MainContainer,
  QueueValue,
  QueueValueRow,
  Value,
} from "./LeadItem.styles";
import { functions, httpsCallable } from "../../../config";
import { format } from "date-fns";
import { UserContext } from "../../../contexts/user-context";
import { transfers } from "../../../helpers";

const LeadItem = ({ lead, agents, assignAgent, queueUser }) => {
  const [formattedDate, setFormattedDate] = useState("");
  const [isNewAction, setIsNewAction] = useState(false);
  const [docId, setDocId] = useState("");
  const [leadToDisplay, setLeadToDisplay] = useState(null);
  const [assignedAgentName, setAssignedAgentName] = useState("-");
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useContext(UserContext);

  const updateDocument = () => {
    setIsLoading(true);
    const updateDocumentFunction = httpsCallable(
      functions,
      "updateNewDocument"
    );

    updateDocumentFunction({ docId })
      .then((result) => {
        // console.log("Document updated:", result);
      })
      .catch((error) => {
        console.error("Error updating document:", error);
      });

    setIsLoading(false);
  };

  const sendAssignedAgent = async (e) => {
    const selectedAgentReportAgentId = agents.filter(
      (agent) => agent.id === e.target.value
    )[0].reportAgentId;

    const transfer = parseInt(transfers[lead.transfer], 10);
    const name = `${lead.first} ${lead.last}`;
    const phone = lead.phone;
    const accessCode = lead.ref;
    const email = lead.email;
    const reportAgentId = selectedAgentReportAgentId;

    if (!transfer || !name || !phone || !reportAgentId) return;

    setIsLoading(true);

    const sendAssignedAgentFn = httpsCallable(functions, "sendAssignedAgent");

    try {
      const result = await sendAssignedAgentFn({
        reportAgentId,
        transfer,
        name,
        phone,
        accessCode,
        email,
      });
      assignAgent(lead.id, e.target.value);
      console.log(result.data); // Process result data
    } catch (error) {
      console.error("Error calling the sendAssignedAgent function:", error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!leadToDisplay || (leadToDisplay && !leadToDisplay.updatedAt)) return;

    const d = format(leadToDisplay.updatedAt.toDate(), "MM/dd/yy HH:mm:ss");
    setFormattedDate(d);
  }, [leadToDisplay]);

  useEffect(() => {
    if (!leadToDisplay) return;

    setDocId(leadToDisplay.id);

    setIsNewAction(leadToDisplay.isNew);
  }, [leadToDisplay]);

  useEffect(() => {
    if (!lead) return;
    setLeadToDisplay(lead);
  }, [lead]);

  useEffect(() => {
    if (!lead || !agents) return;

    const leadAgent = agents.filter((agent) => agent.id === lead.agentId)[0];

    if (leadAgent) setAssignedAgentName(leadAgent.fname);
    setIsLoading(false);
  }, [lead, agents]);

  return (
    <MainContainer>
      {leadToDisplay && (
        <>
          {queueUser ? (
            <>
              <QueueValue>{formattedDate}</QueueValue>
              <QueueValue>{`${leadToDisplay.first} ${leadToDisplay.last}`}</QueueValue>
            </>
          ) : (
            <>
              <Value>{formattedDate}</Value>
              <Value>{leadToDisplay.ref}</Value>
              <Value>{`${leadToDisplay.first} ${leadToDisplay.last}`}</Value>
              <Value>{leadToDisplay.phone}</Value>
              <Value>{leadToDisplay.email}</Value>
              <Value>{leadToDisplay.debt}</Value>
              <Value>{leadToDisplay.dataset}</Value>
              <Value>{leadToDisplay.service}</Value>
              <Value>{assignedAgentName}</Value>
              <Value>
                <select
                  name="agent"
                  id="agent"
                  onChange={sendAssignedAgent}
                  disabled={isLoading}
                >
                  <option value="-" selected disabled>
                    -
                  </option>
                  {agents.map((agent) => (
                    <option key={agent.id} value={agent.id}>
                      {`${agent.fname}`}
                    </option>
                  ))}
                </select>
                {isNewAction ? (
                  <Btn onClick={updateDocument} disabled={isLoading}>
                    New
                  </Btn>
                ) : (
                  "-"
                )}
              </Value>
            </>
          )}
        </>
      )}
    </MainContainer>
  );
};

export default LeadItem;
